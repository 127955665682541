import React from 'react'

const budContentSection = ({ system, elements }) => (
  <section className="main style1">
    <div className="grid-wrapper">
      <div className="col-12">
        <header className="major">
          <h3>中小企融資擔保計劃</h3>
        </header>
        <p>New「百分百擔保特惠貸款」優化措施生效2021年03月22日香港按證保險有限公司宣布，中小企融資擔保計劃下「百分百擔保特惠貸款」的優化措施，將於今年 3月29日（星期一）起生效。</p>
        <p>為進一步紓緩中小企資金周轉的壓力，財政司司長早前於《2021-22年度財政預算案》公布推出「百分百擔保特惠貸款」優化措施，並延長申請期至2021年12月31日。優化措施下，合資格企業為2020年6月30日前已最少營運3個月，並自去年2月份起任何一個單月營業額較該單月前於2019年初至2020年中的任何一個季度的平均每月營業額下跌3成或以上1。每家企業最高貸款額由12個月提高至18個月僱員薪金及租金的總和2，或600萬港元（原為500萬港元），以較低者為準。同時，特惠貸款的最長還款期由5年增加至8年，而還息不還本的安排則由最多12個月增加至最多18個月。</p>
        <p>相關報導：<a href="https://www.info.gov.hk/gia/general/202102/24/P2021022400397.htm" target="_blank">https://www.info.gov.hk/gia/general/202102/24/P2021022400397.htm</a></p>
        <p>「中小企融資擔保計劃」﹙「本計劃」﹚於2011年1月1日由香港按揭證券有限公司 ﹙「按揭證券公司」﹚ 推出。自2018年5月1日起，本計劃之業務已轉移至香港按證保險有限公司 ﹙「按證保險公司」﹚ 並由其繼續經營。按證保險公司為一所由按揭證券公司全資擁有的附屬公司。本計劃旨在協助本地中小型企業﹙「中小企業」﹚及非上市企業﹙統稱「該等企業」﹚從參與計劃的貸款機構﹙「貸款機構」﹚取得融資，應付業務需要，並在急速轉變的營商環境中，提升生產力和競爭力。在本計劃下，按證保險公司可為合資格企業並獲貸款機構批核的貸款提供五成、六成或七成的信貸擔保。</p>
        <p>相關報導：中小企融資擔保計劃閱讀更多關於中小企融資擔保計劃（<a target="_blank" href="https://www.hkmc.com.hk/chi/our_business/sme_financing_guarantee_scheme.html">https://www.hkmc.com.hk/chi/our_business/sme_financing_guarantee_scheme.html</a>)</p>
        <p>相關香港金融管理局- 新聞稿(<a target="_blank" href="https://www.hkma.gov.hk/chi/news-and-media/press-releases/2021/03/20210322-8/">https://www.hkma.gov.hk/chi/news-and-media/press-releases/2021/03/20210322-8/</a>)</p>
      </div>
    </div>
  </section>
)

export default budContentSection