import React from "react"
import Helmet from 'react-helmet'
import '../assets/scss/main.scss'
import SmeContentSection from '../components/SmeContentSection'
import CtaSection from '../components/CTASection'
import GlobalValues from '../components/GlobalValues'

class SmePage extends React.Component {
  render() {
    return (
      <div>
        <Helmet title="中小企融資擔保計劃 (SME Financing Guarantee Scheme)" >
        {
          <script async src={GlobalValues.gtag_link}></script>
        }
        {
          <script>
            {GlobalValues.gtag_config}
          </script>
        }
        </Helmet>

        <SmeContentSection />
        <CtaSection />
      </div>
    )
  }
}
export default SmePage
